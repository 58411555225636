import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// OwlyScan
const Account = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/Account.vue');
const UserProfile = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/UserProfile.vue');
const Organisation = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/Organisation.vue');
const OrganisationMembers = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/OrganisationMembers.vue');
const OrganisationSettings = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/OrganisationSettings.vue');
const OrganisationUsage = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/OrganisationUsage.vue');
const SignupWizard = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/SignupWizard.vue');
// const SearchAssistant = () =>
//   import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/SearchAssistant.vue');
const SearchResults = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/SearchResults.vue');
// const SearchWizard = () =>
//   import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/SearchWizard.vue');
const SearchAlex = () =>
  import(/* webpackChunkName: "owlyscan" */ 'src/pages/OwlyScan/SearchAlex.vue');


// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
const DarknetSearch = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/OwlyScan/DarknetSearch.vue');
const Alerts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/OwlyScan/ManageAlerts.vue');
const Reports = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/OwlyScan/ManageReports.vue');


// Pages
// const User = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const ResetPassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/ResetPassword.vue');
const ResetPasswordChoose = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/ResetPasswordChoosePassword.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');

// Gift code page
const GiftCode = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/OwlyScan/GiftCode.vue');

// let pagesMenu = {
//   path: '/pages',
//   component: DashboardLayout,
//   name: 'Pages',
//   redirect: '/pages/user',
//   children: [
//     {
//       path: 'user',
//       name: 'User Page',
//       components: {default: User}
//     }
//   ]
// };

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/reset-password',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/reset-password-choose',
      name: 'Choose a new Password',
      component: ResetPasswordChoose
    }
  ]
};

let giftPages = {
  path: '/',
  component: AuthLayout,
  name: 'Gift Codes',
  children: [
    {
      path: '/fic24',
      name: 'Salon FIC 24',
      component: GiftCode
    },
    {
      path: '/test2024',
      name: 'Salon du test 2024',
      component: GiftCode
    },
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  // pagesMenu,
  authPages,
  giftPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'account',
        name: 'Account',
        meta: {requiresAuth: true},
        components: {default: Account}
      },
      // {
      //   path: 'user-profile',
      //   name: 'User Profile',
      //   meta: {requiresAuth: true},
      //   components: {default: UserProfile}
      // },
      {
        path: 'organisation',
        name: 'Organisation',
        meta: {requiresAuth: true},
        components: {default: Organisation}
      },
      {
        path: 'organisation-members',
        name: 'Organisation Members',
        meta: {requiresAuth: true},
        components: {default: OrganisationMembers}
      },
      {
        path: 'organisation-settings',
        name: 'Organisation Settings',
        meta: {requiresAuth: true},
        components: {default: OrganisationSettings}
      },
      {
        path: 'organisation-usage',
        name: 'Organisation Usage',
        meta: {requiresAuth: true},
        components: {default: OrganisationUsage}
      },
      {
        path: 'signup',
        name: 'Signup',
        components: {default: SignupWizard}
      },
      {
        path: '/buy',
        name: 'Buy',
        meta: {requiresAuth: true},
        component: Pricing
      },
      {
        path: 'search',
        name: 'Search',
        meta: {requiresAuth: true},
        components: {default: SearchAlex},
        props: true
      },
      {
        path: 'search-results',
        name: 'SearchResults',
        meta: {requiresAuth: true},
        components: {default: SearchResults},
        props: true
      },
      {
        path: 'alerts',
        name: 'Alerts',
        meta: {requiresAuth: true},
        components: {default: Alerts}
      },
      {
        path: 'reports',
        name: 'Reports',
        meta: {requiresAuth: true},
        components: {default: Reports}
      },
    ]
  },
  {path: '*', component: NotFound}
];

export default routes;
