var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.search'),
          icon: 'tim-icons icon-zoom-split',
          path: '/search'
        }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.alerts'), icon: 'tim-icons icon-bell-55',  path: '/alerts' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.reports'), icon: 'tim-icons icon-attach-87',  path: '/reports' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.buyTokens'), icon: 'tim-icons icon-coins',  path: '/buy' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.usefulLinks'), icon: 'tim-icons icon-shape-star' }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.researchWritingCode'),
            isRoute: true,
            path: this.$apiEndpoint + '/api/dork',
            target: '_blank',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.downloadTorBrowser'),
            isRoute: true,
            path: 'https://www.torproject.org/download/',
            target: '_blank',
          }}})],1),_c('sidebar-item',{staticStyle:{"display":"none"},attrs:{"link":{
          name: _vm.$t('sidebar.help'),
          icon: 'tim-icons icon-support-17',
          path: '/help'
        }}})],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }