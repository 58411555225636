<template>
  <footer class="footer">
    <div class="container-fluid">
      <!-- <ul class="nav">
        <li class="nav-item">
          <a
            href="http://creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Creative Tim
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://www.creative-tim.com/presentation"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a
            href="http://blog.creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Blog
          </a>
        </li>
      </ul> -->
      <div class="copyright">
        <span v-if="isDemoMode()">Mode démonstration -</span>
        &copy; {{ year }}
        <a
          href="https://www.owlyscan.com"
          target="_blank"
          rel="noopener"
          >OwlyScan</a
        >
        - v{{ this.$owlyscanVersion }}
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    isDemoMode() {
      return (process.env.NODE_ENV !== 'production')
    }
  }
};
</script>
<style></style>
