/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.4.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import store from "./store";

// router setup
import router from "./routes/router";
import i18n from "./i18n";
// import "./registerServiceWorker";
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false;

export const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
export const OWLYSCAN_VERSION = process.env.VERSION;

Vue.prototype.$apiEndpoint = API_ENDPOINT;
Vue.prototype.$owlyscanVersion = OWLYSCAN_VERSION;


// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueReCaptcha, { siteKey: '6Lc4XqopAAAAADNUyRzAHywBn4Da5M1hBTYBaBEj' })





/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  i18n,
});
