<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button/>
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.search'),
            icon: 'tim-icons icon-zoom-split',
            path: '/search'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.alerts'), icon: 'tim-icons icon-bell-55',  path: '/alerts' }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.reports'), icon: 'tim-icons icon-attach-87',  path: '/reports' }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.buyTokens'), icon: 'tim-icons icon-coins',  path: '/buy' }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.usefulLinks'), icon: 'tim-icons icon-shape-star' }"
        >
          <sidebar-item
            :link="{
              name: $t('sidebar.researchWritingCode'),
              isRoute: true,
              path: this.$apiEndpoint + '/api/dork',
              target: '_blank',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.downloadTorBrowser'),
              isRoute: true,
              path: 'https://www.torproject.org/download/',
              target: '_blank',
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item style="display: none;"
          :link="{
            name: $t('sidebar.help'),
            icon: 'tim-icons icon-support-17',
            path: '/help'
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"></sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import SidebarShare from './SidebarSharePlugin';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

// function initScrollbar(className) {
//   if (hasElement(className)) {
//     new PerfectScrollbar(`.${className}`);
//   } else {
//     // try to init it later in case this component is loaded async
//     setTimeout(() => {
//       initScrollbar(className);
//     }, 100);
//   }
// }

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import {ZoomCenterTransition} from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    // SidebarShare
  },
  data() {
    return {
      sidebarBackground: 'primary' //'vue' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    // initScrollbar() {
    //   let docClasses = document.body.classList;
    //   let isWindows = navigator.platform.startsWith('Win');
    //   if (isWindows) {
    //     // if we are on windows OS we activate the perfectScrollbar function
    //     initScrollbar('sidebar');
    //     initScrollbar('main-panel');
    //     initScrollbar('sidebar-wrapper');

    //     docClasses.add('perfect-scrollbar-on');
    //   } else {
    //     docClasses.add('perfect-scrollbar-off');
    //   }
    // }
  },
  // mounted() {
  //   this.initScrollbar();
  // }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

</style>
