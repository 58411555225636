import {API_ENDPOINT} from "./main"

export function refreshContext(store, defaultOrg=false) {
  let accessToken = localStorage.getItem("userToken");
  let userOrgId =  store.state.user.current_org_id;

  fetch(API_ENDPOINT + "/api/context", {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    }
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return new Error("Cannot fetch context");
    }
  }).then((data) => {
    if (defaultOrg || userOrgId == null) {
      userOrgId = data.orgs.find(org => org.is_default).id;
    }
    data.user.current_org_id = userOrgId;
    store.dispatch('fetchData', data);
    // localStorage.setItem("data", JSON.stringify(data));
  });
}

export function formatDate(date) {
  return date ? new Date(date).toLocaleDateString() : "";
}
export function formatDateTime(date) {
  return date ? new Date(date).toLocaleString() : "";
}
