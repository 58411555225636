<template>
  <div>
    <base-nav
      v-model="showMenu"
      type="white"
      :transparent="true"
      menu-classes="justify-content-end"
      class="auth-navbar fixed-top"
    >
      <div slot="brand" class="navbar-wrapper">
        <a class="navbar-brand" href="#" v-if="title">{{ title }}</a>
      </div>
      <a href="https://owlyscan.com/" class="nav-link text-primary" style="font-weight: bold;">
          <i class="tim-icons icon-minimal-left"></i>{{ $t('login.backToWebsite') }}
        </a>

      <ul class="navbar-nav">
        <router-link class="nav-item" tag="li" to="/register">
          <a class="nav-link">
            <i class="tim-icons icon-laptop"></i> Register
          </a>
        </router-link>

        <router-link class="nav-item" tag="li" to="/login">
          <a class="nav-link">
            <i class="tim-icons icon-single-02"></i> Login
          </a>
        </router-link>

<!--        <router-link class="nav-item" tag="li" to="/pricing">-->
<!--          <a class="nav-link"> <i class="tim-icons icon-coins"></i> Pricing </a>-->
<!--        </router-link>-->


          <!-- <li v-if="this.$i18n.locale === 'en'" class="nav-item">
            <a @click="changeLang('fr')" class="nav-link">Fr</a>
          </li>
          <li v-if="this.$i18n.locale === 'fr'" class="nav-item">
            <a @click="changeLang('en')" class="nav-link">En</a>
          </li> -->

          <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link"
                     menu-classes="dropdown-navbar">
            <template slot="title">
              {{ this.$i18n.locale.toUpperCase() }}
            </template>

            <li v-for="lang in supportedLocales" :key="lang" class="nav-link">
              <a @click="changeLang(lang)" class="nav-item dropdown-item">{{ lang.toUpperCase() }}</a>
            </li>
          </base-dropdown>

      </ul>
    </base-nav>

    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="copyright">
              &copy; {{ year }}
              <a
                href="https://www.owlyscan.com"
                target="_blank"
                rel="noopener"
              >OwlyScan</a
              >
              - v{{ this.$owlyscanVersion }}
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import {BaseNav} from 'src/components';
import {ZoomCenterTransition} from 'vue2-transitions';
import { localeChanged, localize } from 'vee-validate';
// import * as VeeValidate from 'vee-validate';
// import en from 'vee-validate/dist/locale/en';
// import fr from 'vee-validate/dist/locale/fr';
// import es from 'vee-validate/dist/locale/es';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
      supportedLocales: ['fr', 'en', 'es']
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.currentLang = lang;
      this.loadLocale(lang);
      localeChanged();
    },
    loadLocale(code) {
      return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
        localize(code, locale);
      });
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.changeLang(this.$i18n.locale);
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
